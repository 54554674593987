import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import FieldSelector from "../../customComponents/FieldSelector";
import RatePeriodFields from "../Fields/RatePeriodFields";
import yupTypeValidator from "../../../utils/YupTypeValidator";
import * as yup from "yup";
import { useFormik } from "formik";
import { parsedUser } from "../../../utils/GetCurrentUser";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";


import { useTranslation } from "react-i18next";
import moment from "moment";

const RatePeriodGeneral = forwardRef((props, ref) => {
  const { t } = useTranslation();
  // console.log("rate period general", props)
  const [validationsSchema, setValidationSchema] = useState(null);
  const fields = RatePeriodFields;
  const user = parsedUser();
  const toast = useToast();
  const prepareFormikObject = () => {
    //("Preparing formik object", fields)
    let initialObject = {};
    let initialObjectValidation = {};

    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.FieldName] = item.value;

        //TODO: REMOVE THIS CONDITION!!!!!!
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }

    formik.initialValues = initialObject;

    setValidationSchema(yup.object(initialObjectValidation));
    //Now
  };

  const handleSubmit = async (obj) => {
    // console.log(obj, props);
    obj.GUIDBusinessUnits = props.businessUnitSelected.GUID;

    formik.validateForm();
    if (!formik.isValid) {
      props.setRequestToSaveGeneral(false);
      return;
    }

    //Check if the objet has dates between already existant dates
    // console.log(props.allRates)
    let continueSaving = true;
    console.log("Rates", props.allRates)
    let countContracts = props.allRates.filter(x => x.RateType === 'C').length;
    if (countContracts >= 1) {

      props.allRates.forEach((rate) => {
        console.log(formik.values, rate, rate.DateStart.substring(0, 10), obj.DateStart)
        if (rate.GUID !== obj.GUID && formik.values.RateType !== 'S') {
          //Check if the Start date is between the date range
          console.log("teszt", rate.DateEnd, obj.DateEnd)

          let momentRateDateStart = moment(rate.DateStart.substring(0, 10));
          let momentRateDateEnd = moment(rate.DateEnd.substring(0, 10));
          let momentObjDateStart = moment(obj.DateStart.substring(0, 10));
          let momentObjDateEnd = moment(obj.DateEnd.substring(0, 10));

          if (
            momentRateDateStart === momentObjDateStart ||
            (momentObjDateStart >= momentRateDateStart &&
              momentObjDateEnd <= momentRateDateEnd)
          ) {
            continueSaving = false;
            return;
          }



          if (
            rate.DateEnd.substring(0, 10) === obj.DateEnd &&
            (obj.DateStart >= rate.DateEnd.substring(0, 10) &&
              obj.DateEnd <= rate.DateEnd.substring(0, 10))
          ) {
            continueSaving = false;
            return;
          }
        }
      });
    }
    // console.log(continueSaving);
    if (continueSaving === false) {
      toast({
        type: "error",
        message: t("Dates can't be between previous rates"),
      });
      return;
    }

    let objSPVersion = "";
    fields.forEach((item) => {
      if (item.ReadOnly || item.HtmlType === "separator") return;

      if (item.HtmlType !== "separator") {
        objSPVersion +=
          (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
      }
      if (
        item.FieldType === "int" ||
        item.FieldType === "bool" ||
        item.FieldType === "bit" ||
        item.FieldType === "bigint"
      ) {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else if (item.HtmlType === "radio") {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? item.DefaultValue && item.DefaultValue.length > 0
              ? item.DefaultValue
              : "null"
            : "'" + obj[item.FieldName] + "'";
      } else {
        objSPVersion +=
          obj[item.FieldName] === undefined || obj[item.FieldName] === null
            ? "null"
            : "'" + obj[item.FieldName] + "'";
      }
    });

    objSPVersion += `, @GUIDBusinessUnits='${props.businessUnitSelected.GUID}' `;
    let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${props.actionMode}`;
    let queryData = {
      Data: objSPVersion,
    };

    let request = await API.postAction(
      `api/Contracts/RatePeriodAddEdit?${queryString}`,
      queryData
    );

    // console.log(request);
    let response = request.data[0].JSONData;
    let parsedResponse = JSON.parse(response);
    props.setRecord(parsedResponse[0].JSONData);
    props.setRecordGUID(parsedResponse[0].JSONData?.GUID);
    props.setRequestToSaveGeneral(false);
    // props.setActiveStep(props.step + 1);
    if (props.actionMode === "I") {
      props.setActionMode("U");
      moveToNextStep();
    }
  };

  const moveToNextStep = () => {
    props.setActiveStep(props.step + 1);
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,

    validateOnChange: false,
    validateOnBlur: false,

    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });
  const [freeNightsMaxValue, setFreeNightsMaxValue] = useState(false)
  const updateField = (fieldName, fieldValue) => {
    if (fieldName === "RateType") {
      props.setIsSpecial(fieldValue);
    }
    if (fieldName === "FreeNightsCount") {
      console.log(fieldValue);
      setFreeNightsMaxValue(fieldValue > 2);

    }
    if (fieldName === "FreeNights") {
      if (fieldValue === 1) {
        formik.setFieldValue("FreeNightsCount", 1)
      } else {
        formik.setFieldValue("FreeNightsCount", 0)
        formik.setFieldValue("FreeNightsMinStay", 0)
        formik.setFieldValue("FreeNightsMaxStay", 0)

      }
    }
    formik.setFieldValue(fieldName, fieldValue);
    //	setRecord({ ...record, fieldName: fieldValue });
  };

  const fillFormikObject = (record) => {
    // console.log("record", record, "model", fields);
    if (fields && fields.length > 0) {
      fields.forEach((item) => {
        // //		console.log(
        // 			"field name",
        // 			item.FieldName,
        // 			"RecordValue ",
        // 			record[item.FieldName]
        // 		);
        updateField(item.FieldName, record[item.FieldName]);
      });
    }

    //Now
  };

  useEffect(() => {
    //bindRoomTypesRemote();
    prepareFormikObject();
    // console.log(props)
    if (props.record && Object.keys(props.record).length > 0) {
      fillFormikObject(props.record);
    }
    // check to add dates from contract
    if (props.actionMode === "I") {
      formik.setFieldValue("DateStart", props.Contract.StartDate);
      formik.setFieldValue("DateEnd", props.Contract.EndDate);
    }
  }, []);

  useEffect(() => {
    // console.log(props.requestToSave)

    if (props.requestToSave) {
      formik.submitForm();
    }
  }, [props.requestToSave]);

  useImperativeHandle(ref, () => ({
    submit() {
      formik.submitForm();
    },
  }));

  return (
    // <Form onSubmit={formik.handleSubmit}>
    <Card>
      <Card.Body>
        <Row className="d-flex ">
          {fields.map((item, index) => (
            <Col
              className={item.cssClasss + (!item.Visible ? " d-none" : "")}
              key={index}
            >
              <label className="me-2 mt-2">{t(item.FieldTitle)}</label>
              {item.Required ? (
                <i className="required-asterisk ms-1 text-danger">*</i>
              ) : null}
              <br />

              <FieldSelector
                actionMode={props.actionMode}
                section="RatePeriodGeneral"
                sectionReferenceContent={props.Contract}
                model={item}
                key={index}
                obj={formik.values}
                updateField={updateField}
                value={formik.values[item.FieldName]}
              ></FieldSelector>
              {formik.errors[item.FieldName] ? (
                <div className="invalid text-sm">
                  {formik.errors[item.FieldName]}
                </div>
              ) : null}
            </Col>
          ))}
        </Row>
      </Card.Body>
      {freeNightsMaxValue && (

        <Card.Footer>
          <div className="alert alert-warning" role="alert">
            <i class name="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
            {t("WARNING: " + formik.values.FreeNightsCount + " free nights selected ")}
          </div>
        </Card.Footer>

      )}
    </Card>
    // </Form>
  );
});

export default RatePeriodGeneral;
