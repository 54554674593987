import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { parsedUser } from "../../utils/GetCurrentUser";
import { RoomBlockFields } from "./Fields/RoomBlockFields";
import { useFormik } from "formik";
import { useToast } from "../../contexts/toast";
import * as yup from "yup";
import API from "../../api/api";
import FieldSelector from "../customComponents/FieldSelector";
import React, { useEffect, useState } from "react";
import yupTypeValidator from "../../utils/YupTypeValidator";

export const RoomBlockDetails = ({ selectedRoom, actionMode, modalSize, show, toggle, handleClose }) => {
  const fields = RoomBlockFields;
  const toast = useToast();
  const user = parsedUser();
  const [selectedRecord, setSelectedRecord] = useState(selectedRoom);
  const [generalError, setGeneralError] = useState(null);
  // const [actionMode, setActionMode] = useState(actionMode);//I=Insert, U= Update = C= Consultar, D= Delete
  const [isLoading, setIsLoading] = useState(true);
  const [validationsSchema, setValidationSchema] = useState(null);

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  //This method is to save the record we are working on
  const handleSubmit = async (obj, event) => {
    try {
      let objSPVersion = "";
      if (actionMode === "U" || actionMode === "K") {
        // debugger;
        obj.GUID = selectedRecord.GUID;
        obj.Active = true;
      }
      fields.forEach((item) => {
        if (item.ReadOnly ||
          item.HtmlType === "separator" ||
          item.FieldName === 'IdCustomer' ||
          item.FieldName === 'IdContract') return;
        if (item.HtmlType !== "separator") {
          objSPVersion +=
            (objSPVersion.length > 0 ? ", " : "") + `@${item.FieldName}=`;
        }
        if (
          item.FieldType === "int" ||
          item.FieldType === "bool" ||
          item.FieldType === "bit" ||
          item.FieldType === "bigint"
        ) {
          objSPVersion +=
            obj[item.FieldName] === undefined || obj[item.FieldName] === null
              ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
              : "'" + obj[item.FieldName] + "'";
        } else
          if (item.HtmlType === 'radio') {
            objSPVersion +=
              obj[item.FieldName] === undefined || obj[item.FieldName] === null
                ? item.DefaultValue && item.DefaultValue.length > 0 ? item.DefaultValue : "null"
                : "'" + obj[item.FieldName] + "'";
          } else {
            objSPVersion +=
              obj[item.FieldName] === undefined || obj[item.FieldName] === null
                ? "null"
                : "'" + obj[item.FieldName] + "'";
          }
      });
      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}&ActionMode=${actionMode}`;
      if (actionMode === "U" || actionMode === "K") {
        queryString += `&ParentGUID=${selectedRoom.GUID}`
      }
      let queryData = {
        Data: objSPVersion,
      };
      let request = await API.postAction(
        `api/RoomBlock/AddEdit?${queryString}`,
        queryData
      );
      if (request.status === 200) {
        let response = JSON.parse(request.data[0].JSONData)[0];
        if (response.Error) {
          setGeneralError(response.Msg);
          return;
        }
      }
      toast({
        type: "success",
        "message": "Record saved successfully",
      })
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }

  const fillFormikObject = (record) => {
    if (
      fields &&
      fields.length > 0
    ) {
      fields.forEach((item) => {
        formik.setFieldValue(item.FieldName, record[item.FieldName]);
      });
    }
  };

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};
    if (fields) {
      fields.forEach((item) => {
        if (item.FieldName === "Id" || item.FieldName === "File") return;
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: handleSubmit,
  });

  //Bring all the details for the selected record
  const bindDataDetailsRemote = async (item) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${item.GUID}&ParentGUID=${selectedRoom.GUID}`;
      let request = await API.getAction("api/RoomBlock/Details", query);
      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      console.log(recordResult);
      setSelectedRecord(recordResult)
      fillFormikObject(recordResult);
      //   let record = DATAMODEL;
      //   //Now we need to go over the entire result and assign values to the record
      //   Object.keys(recordResult).forEach(item => {

      //     record[item] = recordResult[item];
      //   })
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("🚀 ~ Edit", {actionMode, selectedRoom})
    if (selectedRoom && actionMode === 'U') {
      console.log("🚀 ~ Will update...", {actionMode, selectedRoom})
      bindDataDetailsRemote(selectedRoom);
    }
  }, [selectedRoom, actionMode])

  useEffect(() => {
    prepareFormikObject()
    console.log("🚀 ~ useEffect ~ actionMode", actionMode)
  }, [])

  return (<Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    size={modalSize ?? "md"}
    className="modalRight"
  >
    <Modal.Header closeButton>
      <Modal.Title>Room Block Information</Modal.Title>
    </Modal.Header>
    <Form onSubmit={formik.handleSubmit}>
      <Modal.Body>
        <Container fluid className="overflow-auto">
          <Row className=" mb-4 mt-2">
            <Col xs={12} md={12}>
              <Row>
                {fields &&
                  fields.map((item, index) => {
                    return (
                      <Col className={item.cssClasss + (!item.Visible ? " d-none" : "")} key={`col-${item.FieldName}-${index}`} >
                        <label className="me-2 mt-2">
                          {item.FieldTitle}
                          {item.Required ? <i className="required-asterisk ms-1 text-danger">*</i> : null}
                        </label>
                        <br />
                        <FieldSelector
                          actionMode={actionMode}
                          model={item}
                          obj={formik.values}
                          key={`field-${item.FieldName}`}
                          updateField={updateField}
                          value={formik.values[item.FieldName]}
                        ></FieldSelector>
                        {formik.errors[item.FieldName] ? (
                          <div className="invalid text-sm my-1">
                            {formik.errors[item.FieldName]}
                          </div>
                        ) : null}
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
          {generalError ? (
            <Row>
              <Col xs={12}>
                <div className="alert alert-danger" role="alert">
                  {generalError}
                </div>
              </Col>
            </Row>
          ) : null}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex gx-3 align-items-center justify-content-end">
          {Object.values(formik.errors).length > 0 && (
            <span className="invalid me-2">
              Please check the forms for errors
            </span>
          )}
          <button className="btn me-2" type="button" onClick={handleClose}>Close</button>
          <button className="btn btn-primary me-2" type="button" onClick={() => [formik.submitForm()]}>Save</button>
        </div>
      </Modal.Footer>
    </Form>
  </Modal>
  )
}
