import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import budgetFilterFields from "./budgetFilterFields";
import FieldSelector from "../customComponents/FieldSelector";
import { parsedUser } from "../../utils/GetCurrentUser";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import * as yup from 'yup';
import { useFormik } from "formik";
import yupTypeValidator from "../../utils/YupTypeValidator";

function BudgetContainer() {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterFields, setFilterFields ] = useState(budgetFilterFields);
  const [validationsSchema, setValidationSchema] = useState(null);

  const bindDataRemoteSearch = async (filterObj) => {
    console.log("🚀 ~ bindDataRemote ~ filterObj:", filterObj)
    const {
      BudgetYear,
      IdBudgetType,
      IdDepartment,
      IdCustomer
    } = filterObj;

    try {
      setIsLoading(true);
      const filter = `@Accion='T', @IdBusinessUnit=${user.IdBusinessUnit}, @BudgetYear=${BudgetYear || null}, @IdBudgetType=${IdBudgetType||null}, @IdDepartment=${IdDepartment||null}, @IdCustomer=${IdCustomer||null}`;
      const queryData = {
        Data: filter,
      };
      const path = "api/budget/CRUDpmsBudget";
      const query = `IdUser=${user.IdUser}&Token=${user.Token}`;
      const res = await API.postAction(`${path}?${query}`, queryData);
      const data = res.data[0].JSONData;
      const parsedData = JSON.parse(data)[0].JSONData;
      setRecords(parsedData);
      console.table(parsedData);

    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
        toast({
          type: "error",
          message: e.message,
          width: "auto",
          height: "auto",
        })
      } else {
        console.error(e);
        toast({
          type: "error",
          message: t("Something went wrong"),
          width: "auto",
          height: "auto",
        })
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateField = (fieldName, fieldValue, FieldType) => {
    // console.table(budgetFilterFields);
    if (fieldName.trim() === "IdBudgetSource") {
      // console.log({fieldName, fieldValue})
      switch (fieldValue) {
        case 1:
          budgetFilterFields.forEach((item) => {
            if (item.FieldName === "IdCustomer") {
              item.Visible = true;
              item.Required = true;
            }
            if (item.FieldName === "IdDepartment") {
              item.Visible = false;
              item.Required = false
              formik.setFieldValue("IdDepartment", null);
            }
            formik.setFieldValue(fieldName, fieldValue);
          })
          setFilterFields(budgetFilterFields)
          prepareFormikObject();
          break;
        case 2:
          budgetFilterFields.forEach((item) => {
            if (item.FieldName === "IdCustomer") {
              item.Visible = false;
              item.Required = false;
              formik.setFieldValue("IdCustomer", null);
            }
            if (item.FieldName === "IdDepartment") {
              item.Visible = true;
              item.Required = true
            }
            formik.setFieldValue(fieldName, fieldValue);
          })
          setFilterFields(budgetFilterFields)
          prepareFormikObject();
          break;
        default:
          break;
        }
    }
    formik.setFieldValue(fieldName, fieldValue);
  };

  useEffect(() => {
    prepareFormikObject();
  }, []);

  const prepareFormikObject = () => {
    let initialObject = {};
    let initialObjectValidation = {};

    if (filterFields) {
      filterFields.forEach((item) => {
        initialObject[item.FieldName] = item.value;
        if (item.Required) {
          initialObjectValidation[item.FieldName] = yupTypeValidator(item);
        }
      });
    }
    formik.initialValues = initialObject;
    setValidationSchema(yup.object(initialObjectValidation));
  };

  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: bindDataRemoteSearch,
  });

  const saveMonthBudget = async (record, {department, customer}) => {
    const {
        BudgetMonth,
        BudgetAmount,
        GUID,
        BudgetYear,
        IdBudgetType,
        IdDepartment,
        IdCustomer,
    } = record
    setIsLoading(true);
    try {

      
      const filter = `@Accion='U', 
        @IdBusinessUnit=${user.IdBusinessUnit}, 
        @BudgetMonth=${BudgetMonth || null}, 
        @BudgetAmount=${BudgetAmount || null}, 
        @GUID=${GUID || null},
        @BudgetYear=${formik.values.BudgetYear || null}, 
        @IdBudgetType=${formik.values.IdBudgetType||null}, 
        @IdDepartment=${department||null}, 
        @IdCustomer=${customer||null}
        `;
      const queryData = {
        Data: filter,
      };
      const path = "api/budget/CRUDpmsBudget";
      const query = `IdUser=${user.IdUser}&Token=${user.Token}`;
      const res = await API.postAction(`${path}?${query}`, queryData);
      console.log("🚀 ~ BudgetContainer ~ res:", res)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
        toast({
          type: "error",
          message: e.message,
          width: "auto",
          height: "auto",
        })
      } else {
        console.error(e);
        toast({
          type: "error",
          message: t("Something went wrong"),
          width: "auto",
          height: "auto",
        })
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async () => {
    records.forEach((item) => {
      saveMonthBudget(item, {department: formik.values.IdDepartment, customer: formik.values.IdCustomer});
    })
  };

  return (
    <Container>
      <div className="row mx-0">
        <Col xs={12} md={8} className="mx-auto">
          <div className="dx-card my-4 px-3 py-2">
            <div className="row">
              <div className="col">
                <h5 className={"mt-2"}>Budget</h5>
              </div>
              <div className="col text-end">
                {/* <button className="btn">
                <Search />
              </button>
              <button className="btn" onClick={() => toggleOpen()}>
                <AddBox />
              </button> */}
              </div>
            </div>
          </div>
        </Col>
        {/* FILTER */}
        <Col xs={12} md={8} className="mx-auto">
          <Card className="mb-4">
            <Card.Header>
              <Card.Title>{t("Filter")}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                {filterFields.map((item, index) => (
                  <Col
                    className={
                      item.cssClasss + (!item.Visible ? " d-none" : " mb-2")
                    }
                    key={index}
                  >
                    <label className="me-2 mt-2" style={{ fontSize: "12px" }}>
                      {t(item.FieldTitle)}
                    </label>
                    {item.Required ? (
                      <i className="required-asterisk ms-1 text-danger">*</i>
                    ) : null}

                    <FieldSelector
                      model={item}
                      key={index}
                      obj={formik.values}
                      updateField={(fieldName, value) => updateField(fieldName, value, item.FieldType)}
                      value={formik.values[item.FieldName]}
                    />
                    {formik.errors[item.FieldName] ? (
                      <div className="invalid text-sm">
                        {formik.errors[item.FieldName]}
                      </div>
                    ) : null}
                  </Col>
                ))}
              </Row>
              <Row >
                <Col className="text-end mt-2">
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {t("Search")}
                </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          {/* CONTENT */}
          {records.length > 0 && <Card className="mb-4">
            <Card.Header>
              <Card.Title>{t("General Budget")}</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th>{t("Month")}</th>
                      <th>{t("Budget")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.length > 0
                      ? records.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.Month}</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={item.BudgetAmount}
                                  min={0}
                                  disabled={isLoading}
                                  onChange={(e) => {
                                    // using regex to allow only numbers
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    );
                                    item.BudgetAmount = e.target.value;
                                    setRecords([...records]);
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </Card.Body>
            <Card.Footer className="text-end">
              <button
                className="btn btn-primary"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("Save")}
              </button>
            </Card.Footer>
          </Card>}
        </Col>
      </div>
    </Container>
  );
}

export default BudgetContainer;
